<template>
  <div class="content">
    <img src="@/assets/jxlogo.png" alt class="simg" />
    <div class="jxtit">酒星商户助手</div>
    <div class="btn" @click="download">
      <img
        src="@/assets/android.png"
        alt
        style="width: 37px; height: 42px; margin-right: 20px"
      />
      <span>
        Android
        <span class="downdtext">下载</span>
      </span>
    </div>
    <div class="btn iosbtn" @click="downloadIOS">
      <img
        src="@/assets/Apple@2x.png"
        alt
        style="width: 37px; height: 42px; margin-right: 20px"
      />
      <span>
        iPhone
        <span class="downdtext">下载</span>
      </span>
    </div>
    <div class="hhjbottom">
      <img src="@/assets/b2bottom.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "B2Ddownload",
  data() {
    return {};
  },
  methods: {
    download() {
      window.location.href =
        "https://mobile.baidu.com/item?docid=30001020&source=s1001";
    },
    downloadIOS() {
      window.location.href =
        "https://apps.apple.com/cn/app/%E5%A5%BD%E9%85%92%E9%9B%86%E5%95%86%E6%88%B7%E7%AB%AF/id1575661259";
    },
  },
  created() {
    document.title = "好酒集商户助手下载";
  },
};
</script>
<style lang="scss" scoped>
img {
  display: block;
}
.content {
  width: 100%;
  height: 100%;
  text-align: center;
  background: url("../assets/b2背景@2x.png");
  overflow: auto;
}
.simg {
  margin: 200px auto 0;
}
.jxtit {
  font-size: 48px;
  color: #ffffff;
}
.btn {
  width: 520px;
  height: 90px;
  border: 2px solid #ffffff;
  opacity: 1;
  border-radius: 46px;
  margin: 160px auto 100px;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iosbtn {
  margin-top: 70px;
}

.downdtext {
  margin-left: 20px;
}
.hhjbottom {
  position: absolute;
  bottom: 92px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
