<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>
<script>
export default {
  name:'b2Ddownload',
  data(){
    return {

    }
  },
  created(){
    
  }
}
</script>
<style lang="sass" scoped>

</style>